.welcome {
  padding: 0 0 68px;
  box-sizing: border-box;
  @media all and (max-width: 767px) {
    padding: 0 0 30px;
  }

  &_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &_social_items {
    display: flex;
    margin: 0 auto 10px;
    @media all and (max-width: 767px) {
      margin: 0 auto 20px;
    }

    .social_item {
      padding: 0 34px;
      box-sizing: border-box;

      &_link {
        border-radius: 12px;
        background: #2D2D2D;
        box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.10), 0px 8px 10px -6px rgba(0, 0, 0, 0.10);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 68px;
        height: 68px;
        @media all and (max-width: 767px) {
          width: 48px;
          height: 48px;
        }

        img {
          width: 28px;
          display: block;
          height: auto;
        }
      }
    }
  }

  &_title {
    color: $white_text_color;
    text-align: center;
    font-family: $Inter-700;
    font-size: 48px;
    font-style: normal;
    line-height: 60px;
    text-transform: uppercase;
    margin: 0;
    @media all and (max-width: 767px) {
      font-size: 32px;
      line-height: 38px;
      margin: 0 0 14px;
    }
    @media all and (max-width: 576px) {
      font-size: 20px;
      line-height: 26px;
      margin: 0 0 14px;
    }
  }

  &_sub_title {
    color: $white_text_color;
    text-align: center;
    font-family: $Inter-400;
    font-size: 18px;
    font-style: normal;
    line-height: 28px;
    margin: 0 auto 40px;
    max-width: 628px;
    @media all and (max-width: 576px) {
      font-size: 16px;
      line-height: 22px;
    }
  }

  &_cta {
    button {
      font-size: 20px;
      font-family: $Inter-600;
      padding: 12px 32px;
      line-height: 28px;
    }
  }
}