//@import "aos";
@import "variables";
@import "aos";
@import "header";
@import "./home/index";
@import "./accordion_styles/index";
@import "./common/index";
@import "footer";
@import "services";
@import "loader";



.toast {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  z-index: 1000;
  transform: translateY(-100%);
  transition: all 0.3s ease-in-out;


  &.show {
    transform: translateY(0);
    transition: all 0.3s ease-in-out;
  }

  &_inner {
    background: #4D9636;
    color: #fff;
    font-family: Inter-400, sans-serif;
    padding: 8px 16px;
    box-sizing: border-box;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }
}

html {
  scroll-behavior: smooth;
  background: #1d1f20;
}

ul, ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

* {-webkit-tap-highlight-color: rgba(0, 0, 0, 0)}
* { outline: none}

body {
  margin: 0;
  padding: 0;
  background: rgb(14, 35, 12);
  background: linear-gradient(180deg, rgba(14, 35, 12, 1) 0%, rgba(34, 91, 31, 1) 50%, rgba(14, 35, 12, 1) 100%);
  position: relative;

  &:before {
    z-index: 1;
    position: absolute;
    left: 0;
    top: 0;
    content: '';
    width: 159px;
    height: 100%;
    background: linear-gradient(90deg, #2D2D2D 0%, rgba(0, 0, 0, 0.00) 100%);
    @media all and (max-width: 1300px) {
      width: 100px;
    }
    @media all and (max-width: 1200px) {
      width: 64px;
    }
    @media all and (max-width: 768px) {
      width: 32px;
    }
  }

  &:after {
    z-index: 0;
    position: absolute;
    right: 0;
    top: 0;
    content: '';
    width: 159px;
    height: 100%;
    background: linear-gradient(270deg, #2D2D2D 0%, rgba(0, 0, 0, 0.00) 100%);
    @media all and (max-width: 1300px) {
      width: 100px;
    }
    @media all and (max-width: 1200px) {
      width: 64px;
    }
    @media all and (max-width: 768px) {
      width: 32px;
    }
  }

  .shadow {
    position: relative;
  }

  &.is_menu_visible {
    overflow: hidden;
  }
}

.container {
  max-width: 1460px;
  width: 100%;
  padding: 0 60px;
  margin: 0 auto;
  box-sizing: border-box;
  @media all and (max-width: 1200px) {
    padding: 0 24px;
  }
  @media all and (max-width: 1024px) {
    max-width: 808px;
  }
  @media all and (max-width: 767px) {
    padding: 0 16px;
  }
}