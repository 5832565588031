
.page_loader {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  background: $bg_color;
  z-index: 100;
  transition: all 1s ease-in-out;

  &_content {
    display: flex;
    //padding-left: 54px;
    justify-content: center;
  }

  &.loading {
    opacity: 1;
    z-index: 100;
    transition: all 1s ease-in-out;
  }

  &.loaded {
    opacity: 0;
    z-index: -1;
    transition: all 1s ease-in-out;
  }

  img {
    width: 86px;
    display: block;
  }

  svg {
    font-family: $Inter-700;
    width: 100%;
    height: 100%;
  }

  svg text {
    text-transform: uppercase;
    animation: stroke 2s forwards;

    stroke-width: 2;
    stroke: #fff;
    font-size: 140px;
  }

  @keyframes stroke {
    0% {
      fill: rgba(255, 255, 255, 0);
      stroke: rgba(255, 255, 255, 1);
      stroke-dashoffset: 25%;
      stroke-dasharray: 0 50%;
      stroke-width: 2;
    }
    70% {
      fill: rgba(255, 255, 255, 0);
      stroke: rgba(255, 255, 255, 1);
    }
    80% {
      fill: rgba(255, 255, 255, 0);
      stroke: rgba(255, 255, 255, 1);
      stroke-width: 3;
    }
    100% {
      fill: rgba(255, 255, 255, 1);
      stroke: rgba(255, 255, 255, 0);
      stroke-dashoffset: -25%;
      stroke-dasharray: 50% 0;
      stroke-width: 0;
    }
  }
}
