.faqs_section {
  scroll-margin-top: 150px;

  &_wrapper {
    margin: 0 auto;
    padding: 100px 0 160px;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-width: 980px;
    @media all and (max-width: 1024px) {
      padding: 50px 0 100px;
    }
    @media all and (max-width: 767px) {
      padding: 0 0 100px;
    }
  }

  &_title {
    color: $white_text_color;
    text-align: center;
    font-family: $Inter-700;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    margin: 0 0 56px;
    line-height: 60px; /* 125% */
    @media all and (max-width: 767px){
      font-size: 24px;
      line-height: 26px;
      margin: 0 0 30px;
    }
  }

  &_subtitle {
    color: $white_text_color;
    font-family: $Inter-400;
    font-size: 16px;
    font-style: normal;
    line-height: 30px;
    margin: 0 auto 40px;
    text-align: center;
    max-width: 750px;
  }
}