.accordion_container {
  width: 100%;

  .accordion_data {
    .accordion_item_wrapper {
      margin-bottom: 20px;
      transition: all 0.3s ease-in-out;
      position: relative;
      border-radius: 24px;
      border: 3px solid #4D9636;
      background: #424242;
      backdrop-filter: blur(6px);

      &.is_active {
        .accordion_item {
          transition: margin-bottom 0.3s ease;

          &_icon {
            transition: transform 0.5s ease;
            transform: rotateX(0);
          }
        }

        .accordion_item_body {
          transition: height 0.3s ease, transform 0.3s ease, opacity 0.3s ease;
          transform-origin: top;
          opacity: 1;
          display: block;
          transform: scaleY(1);
          overflow: hidden;
          padding: 0 20px 20px;
          height: auto;
        }
      }

      .accordion_item {
        padding: 6px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        transition: margin-bottom 0.3s ease;

        &_title {
          font-family: $Inter-600;
          font-size: 16px;
          flex: 1;
          line-height: 140%;
          transition: color 0.3s ease-in-out;
          margin: 0 30px 0 0;
          color: $white_text_color;
          @media all and (max-width: 768px) {
            font-size: 14px;
          }
        }

        &_icon {
          transform: rotateX(180deg);
          transition: color 0.3s ease-in-out, transform 0.5s ease;
          font-size: 34px;
          font-family: $Inter-400;
          color: $white_text_color;
          width: 42px;
          height: 42px;

          display: flex;
          align-items: center;
          justify-content: center;
          line-height: 1;
          box-sizing: border-box;
          padding-bottom: 2px;
          svg {
            width: 28px;
            height: 28px;
          }
        }
      }

      .accordion_item_body {
        transition: height 0.3s ease, transform 0.3s ease, margin .3s ease;
        font-family: $Inter-400;
        color: $white_text_color;
        height: 0;
        padding: 0 12px;
        transform: scaleY(0);
        opacity: 0;
        transform-origin: top;
        box-sizing: border-box;
        font-size: 14px;
        line-height: 140%;
        @media all and (max-width: 767px) {
          font-size: 12px;
        }
      }
    }
  }
}
