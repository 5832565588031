.services {
  color: #FFFFFF;

  h1 {
    font-family: $Inter-700;
    font-size: 42px;
    text-align: center;
  }

  h4 {
    margin: 0 0 20px;
    font-family: $Inter-500;
    font-size: 32px;
  }

  p {
    font-family: $Inter-400;
  }
}