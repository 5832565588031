.banner {
  padding: 202px 0 80px;

  @media all and (max-width: 767px) {
    padding: 188px 0 40px;
  }

  &_wrapper {
    display: flex;
    align-items: center;
    @media all and (max-width: 1024px) {
      flex-direction: column;
    }
  }

  &_title {
    width: 50%;
    padding: 0;
    box-sizing: border-box;
    @media all and (max-width: 1024px) {
      width: 100%;
      display: flex;
      padding: 0;
      margin-bottom: 80px;
      position: relative;
      flex-direction: column;
    }
    @media all and (max-width: 576px) {
      margin-bottom: 30px;
    }

    .title_title {
      position: relative;
      z-index: 2;
      color: $white_text_color;
      font-family: $Inter-700;
      font-size: 48px;
      font-style: normal;
      line-height: 56px; /* 100% */
      letter-spacing: -0.56px;
      margin: 0;
      text-transform: uppercase;
      display: flex;
      flex-direction: column;
      @media all and (max-width: 1024px) {
        margin-bottom: 60px;
      }

      @media all and (max-width: 576px) {
        margin-bottom: 20px;
      }

      .bold {
        font-family: $Inter-900;
        color: #4D9636;
      }

      @media all and (max-width: 1300px) {
        font-size: 48px;
        line-height: 52px;
      }
      @media all and (max-width: 1200px) {
        font-size: 36px;
        line-height: 44px;
      }
      @media all and (max-width: 1024px) {
        //width: 400px;
      }
      @media all and (max-width: 576px) {
        font-size: 28px;
        line-height: 34px;
      }
    }

    .banner_rocket {
      margin-top: -40px;

      @media all and (max-width: 1024px) {
        position: absolute;
        margin-top: -20px;
        top: 0;
        right: 0;
        width: 300px;
      }
      @media all and (max-width: 576px) {
        width: 160px;
        right: 20px;
      }

      img {
        width: 100%;
        height: auto;
        display: block;
        max-width: 464px;
        @media all and (max-width: 1024px) {

        }
      }
    }

    .couter_game {
      h3 {
        text-align: center;
        font-family: $Inter-600;
        color: #fff;
        font-size: 24px;
        margin-bottom: 40px;
        @media all and (max-width: 992px) {
          font-size: 20px;
          margin-bottom: 30px;
        }
      }

      .play_cta {
        width: 100%;
        display: flex;
        font-family: $Inter-400;
        color: white;
        font-size: 24px;
        justify-content: center;
        margin: 0 auto;
        text-decoration: none;
        transform: scale(1);
        animation: full 1s linear infinite;
        .bonus {
          padding: 0 4px;
          box-sizing: border-box;
          font-family: $Inter-600;
          color: #4D9636;
          text-decoration: underline;
        }
        @media all and (max-width: 992px) {
          font-size: 16px;
        }
      }
      @keyframes full {
        0% {
          transform: scale(1);
        }
        25% {
          transform: scale(0.95);
        }
        50% {
          transform: scale(1);
        }
        75% {
          transform: scale(1.05);
        }
        100% {
          transform: scale(1);
        }
      }

      p {
        text-align: center;
        font-family: $Inter-400;
        color: #fff;
        font-size: 16px;
        margin: 0 0 12px;

        &:nth-child(3) {
          margin-bottom: 30px;
        }
      }

      .countdown_container {
        max-width: 400px;
        margin: 0 auto;

        .countdown_wrapper {
          display: flex;
          justify-content: space-between;

          @media all and (max-width: 992px) {
            max-width: 400px;
            margin: 0 auto;
            justify-content: center;
          }

          .count_item {
            //min-width: 70px;
            font-family: $Inter-400;
            color: #fff;
            display: flex;
            align-items: baseline;
            line-height: 1.1;
            margin-right: 6px;
            //justify-content: center;
            @media all and (max-width: 576px) {
              //min-width: 60px;
            }

            .count {
              font-size: 30px;
              margin-right: 2px;
              @media all and (max-width: 576px) {
                font-size: 24px;
                //min-width: 42px;
              }
            }

            .count_name {
              font-size: 14px;
            }
          }
        }
      }
    }
  }


  .title_description {
    color: $white_text_color;
    font-family: $Inter-400;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.6px;
    line-height: 28px; /* 140% */
    margin: 0 0 60px;
    @media all and (max-width: 1200px) {
      font-size: 18px;
    }
    @media all and (max-width: 767px) {
      margin: 0 0 40px;
    }
  }

  &_progress {
    position: relative;
    z-index: 1;
    width: 50%;
    padding: 0 0 0 50px;
    box-sizing: border-box;
    @media all and (max-width: 1024px) {
      width: 100%;
      padding: 0;
      margin-bottom: 40px;
    }

    .progress_wrapper {
      border-radius: 20px;
      border: 1px solid #FFF;
      background: rgba(255, 255, 255, 0.04);
      box-shadow: 0 10px 60px 0 rgba(0, 0, 0, 0.08);
      backdrop-filter: blur(20px);
      padding: 32px 36px;
      box-sizing: border-box;
      @media all and (max-width: 1200px) {
        padding: 24px 16px;
      }

      .progress_promo {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 28px;
        @media all and (max-width: 1300px) {
          flex-direction: column;
        }

        &_text {
          margin-right: 20px;
          display: flex;
          color: $white_text_color;
          font-family: $Inter-400;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          flex-wrap: wrap;
          justify-content: center;
          @media all and (max-width: 1300px) {
            justify-content: center;
          }

          span {
            white-space: nowrap;
            margin-right: 6px;
            margin-bottom: 4px;
          }

          @media all and (max-width: 1300px) {
            margin: 0 0 20px;
          }
          @media all and (max-width: 767px) {
            font-size: 14px;
          }

          &_bold {
            font-family: $Inter-700;
            box-sizing: border-box;
          }

          &_green {
            box-sizing: border-box;
            font-family: $Inter-700;
            background: linear-gradient(180deg, #3CEFA3 0%, #F6FC5A 100%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }

        &_social {
          display: flex;
          margin: 0 -10px;

          .social_item {
            padding: 0 10px;
            box-sizing: border-box;
          }
        }
      }

      .progress_stage {
        position: relative;
        display: flex;
        justify-content: space-between;
        border-radius: 20px;
        padding: 16px 22px;
        box-sizing: border-box;
        box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.06);
        margin-bottom: 30px;
        @media all and (max-width: 767px) {
          padding: 10px 16px;
        }


        &:after {
          border-radius: 20px;
          z-index: 0;
          position: absolute;
          content: '';
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          opacity: 0.08;
          background: linear-gradient(0deg, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.50) 100%), rgba(255, 255, 255, 0.50);
          backdrop-filter: blur(10px);
        }

        &_stage {
          display: flex;

          .stage_check {
            display: flex;
            height: 48px;
            width: 48px;
            align-items: center;
            justify-content: center;
            margin-right: 18px;
            border-radius: 16px;
            background: rgba(255, 255, 255, 0.12);

            svg {
              display: block;
            }
          }

          .stage_data {
            display: flex;
            flex-direction: column;

            &_title {
              color: $white_text_color_50;
              font-feature-settings: 'clig' off, 'liga' off;
              font-family: $Inter-400;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px;
              display: block;
              margin-bottom: 3px;
            }

            &_value {
              color: $white_text_color;
              font-family: $Inter-700;
              font-size: 22px;
              font-style: normal;
              font-weight: 700;
              line-height: 24px;
              @media all and (max-width: 1200px) {
                font-size: 18px;
              }
              @media all and (max-width: 767px) {
                font-size: 16px;
              }
            }
          }
        }

        &_raised {
          .raised_data {
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            &_title {
              color: $white_text_color_50;
              font-feature-settings: 'clig' off, 'liga' off;
              font-family: $Inter-400;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px;
              display: block;
              margin-bottom: 3px;
            }

            &_value {
              color: $white_text_color;
              font-family: $Inter-700;
              font-size: 22px;
              font-style: normal;
              font-weight: 700;
              line-height: 24px;
              @media all and (max-width: 1200px) {
                font-size: 18px;
              }
              @media all and (max-width: 767px) {
                font-size: 16px;
              }
            }
          }
        }
      }

      .progress_progress {
        overflow: hidden;
        position: relative;
        border-radius: 20px;
        padding: 16px 22px;
        box-sizing: border-box;
        border: 2px solid #5c6a86;
        display: flex;
        justify-content: space-between;
        margin-bottom: 26px;
        @media all and (max-width: 767px) {
          padding: 10px 16px;
        }

        &:after {
          position: absolute;
          content: attr(data-pct);
          height: 100%;
          width: var(--prog);
          top: 0;
          left: 0;
          border-radius: 20px 0 0 20px;
          opacity: 0.8;
          background: linear-gradient(-90deg, #3CEFA3 0%, #F6FC5A 100%);
          box-shadow: 0 10px 30px 0 rgba(118, 243, 140, 0.50);
          z-index: -1;
        }

        .pct_sold {
          display: flex;
          align-items: center;

          &_value {
            color: $white_text_color;
            font-feature-settings: 'clig' off, 'liga' off;
            text-shadow: 2px 2px 0 rgba(25, 25, 25, 0.12);
            font-family: $Inter-700;
            font-size: 38px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px;
            margin-right: 14px;
            @media all and (max-width: 1200px) {
              font-size: 30px;
            }
            @media all and (max-width: 767px) {
              font-size: 24px;
            }
          }

          &_text {
            width: 50px;
            color: $white_text_color;
            font-feature-settings: 'clig' off, 'liga' off;
            font-family: $Inter-400;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
            @media all and (max-width: 767px) {
              font-size: 12px;
            }
          }
        }

        .remaining {
          display: flex;
          flex-direction: column;
          align-items: flex-end;

          &_title {
            color: $white_text_color_50;
            font-feature-settings: 'clig' off, 'liga' off;
            font-family: $Inter-400;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            display: block;
            margin-bottom: 3px;
          }

          &_value {
            color: $white_text_color;
            font-family: $Inter-700;
            font-size: 22px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
            @media all and (max-width: 1200px) {
              font-size: 18px;
            }
            @media all and (max-width: 767px) {
              font-size: 14px;
            }
          }
        }
      }

      .progress_data {
        display: flex;
        margin: 0 -10px 16px;
        justify-content: space-between;

        &_price {
          align-items: flex-start;
        }

        &_sold {
          align-items: center;

          .value {
            font-size: 22px !important;
            @media all and (max-width: 1200px) {
              font-size: 20px !important;
            }
            @media all and (max-width: 767px) {
              font-size: 16px !important;
            }
          }
        }

        &_next {
          align-items: flex-end;
        }


        &_item {
          padding: 0 10px;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;

          .title {
            color: $white_text_color_50;
            font-feature-settings: 'clig' off, 'liga' off;
            font-family: $Inter-400;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            display: block;
            margin-bottom: 3px;
            @media all and (max-width: 767px) {
              //font-size: 1px;
              white-space: nowrap;
            }
            @media all and (max-width: 576px) {
              font-size: 10px;
            }
          }

          .value {
            color: $white_text_color;
            font-feature-settings: 'clig' off, 'liga' off;
            font-family: $Inter-700;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
            @media all and (max-width: 1200px) {
              font-size: 14px;
            }
          }
        }
      }

      .progress_ccy {
        display: flex;
        justify-content: center;
        margin-bottom: 38px;
      }

      .progress_cta {
        text-decoration: none;
        display: block;
        width: 100%;
        margin: 0 0 16px;

        .main_button {
          width: 100%;
          padding: 16px 48px;
        }
      }

      .progress_bottom_cta {
        display: flex;
        margin: 0 -6px;
        @media all and (max-width: 576px) {
          flex-wrap: wrap;
        }

        .main_button {
          text-decoration: none;
          padding: 6px 12px;
          width: 100%;
          @media all and (max-width: 576px) {
            padding: 3px 12px;
          }
        }

        .progress_how, .progress_tg {

          text-decoration: none;
          flex: 1;
          margin: 0 6px 6px;
          display: block;
          color: $white_text_color;
          font-size: 16px;
          text-align: center;
          font-family: $Inter-400;
          line-height: 22px;
          @media all and (max-width: 576px) {
            flex: auto;
            width: 100%;
          }
        }
      }
    }
  }
}