.tokenomics {
  position: relative;
  padding: 30px 0 230px;
  box-sizing: border-box;
  @media all and (max-width: 1024px) {
    padding: 30px 0 130px;
  }
  @media all and (max-width: 576px) {
    padding: 30px 0 290px;
  }
  &_title {
    text-align: center;
    color: $white_text_color;
    font-family: $Inter-700;
    font-size: 60px;
    font-style: normal;
    font-weight: 700;
    line-height: 75px;
    margin: 0 0 16px;

    @media all and (max-width: 767px) {
      font-size: 32px;
      line-height: 38px;
    }

    @media all and (max-width: 576px) {
      font-size: 24px;
      line-height: 26px;
    }
  }

  &_sub_title {
    text-align: center;
    color: $white_text_color;
    font-family: $Inter-400;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    margin: 0 0 32px;
    @media all and (max-width: 767px) {
      font-size: 20px;
      line-height: 18px;
    }
    @media all and (max-width: 576px) {
      font-size: 16px;
      line-height: 24px;
    }
  }

  &_contract {
    border-radius: 9999px;
    background: rgba(0, 0, 0, 0.40);
    padding: 16px 188px 16px 16px;
    box-sizing: border-box;
    position: relative;
    max-width: 770px;
    width: 100%;
    margin: 0 auto 70px;
    @media all and (max-width: 576px) {
      margin: 0 auto 40px;
      padding: 10px 150px 10px 10px;
    }

    .copytextarea {
      opacity: 0;
      position: absolute;
      z-index: -1;
    }

    p {
      color: #FFF;
      font-family: $Inter-300;
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: 24px;
      margin: 0;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      @media all and (max-width: 576px) {
        font-size: 12px;
      }
    }

    button {
      cursor: pointer;
      position: absolute;
      right: 0;
      top: 0;
      padding: 0 32px;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      border-radius: 9999px;
      background: #4D9636;
      border: none;
      box-shadow: 0 25px 50px -12px rgba(255, 246, 34, 0.30);
      @media all and (max-width: 576px) {
        padding: 0 16px;
      }

      img {
        margin-left: 8px;
        cursor: pointer;
      }
    }
  }

  &_chart_wrapper {
    position: relative;
    border-radius: 32px;
    border: 3px solid #4D9636;
    background: rgba(32, 32, 32, 0.50);
    padding: 64px;
    box-sizing: border-box;
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    @media all and (max-width: 1024px) {
      padding: 32px;
    }
    @media all and (max-width: 576px) {
      padding: 16px;
    }

    .chart {
      height: 640px;
      position: relative;
      @media all and (max-width: 1024px) {
        width: 100%;
        height: 420px;
      }
      @media all and (max-width: 576px) {
        height: 320px;
      }


      .inner {
        position: absolute;
        width: 420px;
        height: 420px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background: #2d2d2d;
        z-index: 0;

        @media all and (max-width: 1024px) {
          width: 300px;
          height: 300px;
        }

        @media all and (max-width: 576px) {
          width: 200px;
          height: 200px;
        }

        img {
          width: 57%;
          height: auto;
        }


        span {
          &:first-child {
            font-family: $Inter-400;
            font-size: 16px;
            line-height: 140%;
            margin: 0;
            color: $white_text_color_70;
            @media all and (max-width: 1100px) {
              font-size: 14px;
            }
            @media all and (max-width: 576px) {
              font-size: 12px;
            }
          }

          &:last-child {
            font-family: $Inter-700;
            font-size: 30px;
            line-height: 120%;
            color: $white_text_color;
            @media all and (max-width: 1100px) {
              font-size: 24px;
            }
            @media all and (max-width: 576px) {
              font-size: 20px;
            }
          }
        }
      }
    }
  }

  .label_text {
    font-family: $Inter-400;
    font-size: 8px;
    fill: #fff
  }

  .label_value {
    font-family: $Inter-600;
    font-size: 10px;
  }

  .data_items {
    width: 90%;
    left: 50%;
    max-width: 980px;
    transform: translateX(-50%);
    display: flex;
    position: absolute;
    bottom: -60px;
    border-radius: 24px;
    border: 2px solid #4D9636;
    background: #2D2D2D;
    @media all and (max-width: 1024px) {
      bottom: -40px;
    }

    @media all and (max-width: 576px) {
      flex-direction: column;
      bottom: -222px;
    }
    @media all and (max-width: 400px) {
      bottom: -221px;
    }

    &_item {
      display: flex;
      flex-direction: column;
      padding: 16px 16px 32px;
      box-sizing: border-box;
      border-right: 1px solid #4D9636;
      flex: 1;
      @media all and (max-width: 1024px) {
        padding: 16px;
      }

      @media all and (max-width: 576px) {
        border-right: none;
        border-bottom: 1px solid #4D9636;
        flex-direction: row;
        justify-content: center;
      }

      h3, p {
        color: #FFF;
        font-family: $Inter-700;
        font-size: 19px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
        margin: 0;
        @media all and (max-width: 1024px) {
          font-size: 14px;
          line-height: 22px;
        }
        @media all and (max-width: 576px) {
          font-size: 16px;
        }
      }
      h3 {
        @media all and (max-width: 576px) {
          margin-right: 6px;
        }
      }

      &:last-child {
        border-right: none;
        @media all and (max-width: 576px) {
          border-bottom: none;
        }
      }
    }
  }
}