.main_button {
  border-radius: 8px;
  background: #4D9636;
  box-shadow: 0px 25px 50px -12px rgba(255, 246, 34, 0.30);
  padding: 8px 32px;
  box-sizing: border-box;
  color: $white_text_color;
  font-family: $Inter-400;
  font-size: 12px;
  font-style: normal;
  line-height: 16px;
  text-transform: uppercase;
  border: 1px solid transparent;
  white-space: nowrap;

  &:hover {
    cursor: pointer;
  }
}