.win_in {
  padding: 27px 0 50px;

  @media all and (max-width: 1024px){
    padding: 40px 0 50px;
  }

  @media all and (max-width: 576px){
    padding: 40px 0 36px;
  }

  &_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &_title {
    text-transform: uppercase;
    max-width: 690px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    margin: 0 -4px 44px;
    color: #FFF;
    font-family: $Inter-700;
    font-size: 48px;
    font-style: normal;
    line-height: 60px;

    @media all and (max-width: 767px){
      font-size: 32px;
      line-height: 38px;
      margin: 0 -4px 30px;
      max-width: 520px;
    }

    @media all and (max-width: 576px){
      font-size: 20px;
      line-height: 26px;
      max-width: 330px;
    }

    span {
      padding: 0 4px;
      box-sizing: border-box;
    }

    &_green {
      color: $green_text_color;
    }
  }

  &_buttons {
    display: flex;
    justify-content: center;
    margin: 0 -8px;
    @media all and (max-width: 576px){
      flex-wrap: wrap;
      align-content: center;
    }

    .win_in_button {
      padding: 0 8px;
      box-sizing: border-box;
      @media all and (max-width: 576px){
        margin-bottom: 14px;
      }

      button {
        cursor: pointer;
        padding: 9px 36px;
        box-sizing: border-box;
        border-radius: 8px;
        color: $white_text_color;
        font-family: $Inter-600;
        font-size: 12px;
        font-style: normal;
        line-height: 16px; /* 133.333% */
        text-transform: uppercase;
      }

      .how {
        background: #E99138;
        box-shadow: 0px 25px 50px -12px rgba(255, 246, 34, 0.30);
        border: 1px solid #E99138;
      }

      .buy {
        background: #4D9636;
        box-shadow: 0px 25px 50px -12px rgba(255, 246, 34, 0.30);
        border: 1px solid #4D9636;
      }

      .join {
        background: transparent;
        border: 1px solid #4D9636;
      }
    }
  }
}