.introducing {
  scroll-margin-top: 150px;
  padding: 66px 0 70px;
  @media all and (max-width: 1024px) {
    padding: 40px 0 40px;
  }

  &_wrapper {
    display: flex;
    align-items: stretch;
    height: 100%;
    @media all and (max-width: 1024px) {
      flex-wrap: wrap;
    }
  }

  &_media {
    margin-right: 40px;
    max-width: 704px;
    width: 55%;
    border-radius: 25px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    @media all and (max-width: 1024px) {
      width: 100%;
      margin: 0 0 30px;
      max-width: inherit;
    }
  }

  &_content {
    width: 45%;
    display: flex;
    flex-direction: column;
    @media all and (max-width: 1024px) {
      width: 100%;
    }

    &_title {
      color: $white_text_color;
      font-family: $Inter-600;
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: 48px;
      margin: 0 0 12px;
      display: flex;
      flex-direction: column;
      @media all and (max-width: 1024px) {
        line-height: 36px;
      }
      @media all and (max-width: 576px) {
        font-size: 20px;
        line-height: 26px;
      }
    }

    &_description {
      color: $white_text_color;
      font-family: $Inter-400;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      margin: 0 0 24px;
    }

    &_buttons {
      display: flex;

      .button_buy {
        margin-right: 18px;
        @media all and (max-width: 576px) {
          width: 50%;
        }
        button {
          cursor: pointer;
          @media all and (max-width: 576px) {
            width: 100%;
          }
        }

      }

      .button_whitepaper {
        cursor: pointer;
        @media all and (max-width: 576px) {
          width: 50%;
        }

        button {
          cursor: pointer;
          border-radius: 8px;
          background: transparent;
          border: 1px solid #4D9636;
          padding: 8px 32px;
          box-sizing: border-box;
          color: $white_text_color;
          font-family: $Inter-400;
          font-size: 12px;
          font-style: normal;
          line-height: 16px;
          text-transform: uppercase;
          white-space: nowrap;

          @media all and (max-width: 576px) {
            width: 100%;
          }
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
}