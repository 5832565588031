.big_features {
  padding: 60px 0 80px;
  @media all and (max-width: 1024px) {
    padding: 30px 0 50px;
  }

  &_wrapper {
    border-radius: 32px;
    border: 3px solid #4D9636;
    background: #202020;
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    padding: 64px 32px 120px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media all and (max-width: 1024px) {
      padding: 64px 64px 120px;
    }
    @media all and (max-width: 767px) {
      padding: 64px 32px 24px;
    }
    @media all and (max-width: 576px) {
      padding: 32px 16px 0;
    }
  }

  &_title {
    color: $white_text_color;
    font-family: $Inter-600;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 48px;
    margin: 0;
    @media all and (max-width: 576px) {
      font-size: 20px;
      line-height: 26px;
      margin-bottom: 10px;
    }
  }

  &_subtitle {
    color: $green_text_color;
    text-align: center;
    font-family: $Inter-700;
    font-size: 48px;
    font-style: normal;
    line-height: 60px; /* 125% */
    margin: 0 0 80px;
    @media all and (max-width: 1024px) {
      font-size: 32px;
      line-height: 48px;
    }
    @media all and (max-width: 576px) {
      font-size: 20px;
      line-height: 26px;
      margin: 0 0 60px;
    }

  }

  &_list {
    margin: 0 -16px;
    display: flex;
    @media all and (max-width: 1024px) {
     flex-direction: column;
      align-items: center;
    }
    .list_items {
      flex: 2;
      display: flex;
      flex-direction: column;
      padding: 0 16px;
      box-sizing: border-box;

      .list_item {
        display: flex;
        align-items: center;
        padding: 0 0 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid #7F7F7F;
        &:last-child {
          border-bottom: none;
        }

        &_img {
          width: 56px;
          height: 56px;
          margin-right: 16px;
        }

        &_content {
          .content_title {
            color: $green_text_color;
            font-family: $Inter-600;
            font-size: 16px;
            font-style: normal;
            line-height: 24px;
            margin: 0;
          }

          .content_text {
            color: $white_text_color;
            font-family: $Inter-400;
            font-size: 16px;
            font-style: normal;
            line-height: 24px;
            margin: 0;
            @media all and (max-width: 576px) {
              font-size: 14px;
              line-height: 22px;
            }
          }
        }
      }
    }

    .list_logo {
      width: 228px;
      display: flex;
      justify-content: center;
      align-items: center;
      @media all and (max-width: 1024px) {
        margin-bottom: 40px;
      }
      @media all and (max-width: 767px) {
        width: 150px;
      }
      img {
        width: 100%;
        height: auto;
      }

    }
  }
}