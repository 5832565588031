@import "variables";

body.is_menu_visible {
  .nav_list_main {
    @media all and (max-width: 1024px) {
      transform: scaleY(1);
      transform-origin:  top;
      transition: 0.3s scale ease-in-out, 0.5s opacity ease-in-out;
      opacity: 1;
    }
  }
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  font-family: $Inter-400;
  z-index: 100;
  background: #1d1f20;
  box-sizing: border-box;
  border-bottom: 8px solid #4D9636;
  @media all and (max-width: 576px) {
    padding: 0 0 12px;
  }

  .top_line {
    background-color: #4D9636;
    padding: 8px 0;
    @media all and (max-width: 576px) {
      margin-bottom: 12px;
    }
    &_text {
      display: flex;
      color: $white_text_color;
      font-family: $Inter-400;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      flex-wrap: wrap;
      justify-content: center;

      span {
        white-space: nowrap;
        margin-right: 6px;
        margin-bottom: 4px;
      }

      @media all and (max-width: 576px) {
        font-size: 12px;
      }

      &_bold {
        font-family: $Inter-700;
        box-sizing: border-box;
      }

      &_green {
        box-sizing: border-box;
        font-family: $Inter-700;
        background: linear-gradient(180deg, #3CEFA3 0%, #F6FC5A 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }

  &_wrapper {
    display: flex;
    align-items: center;
    position: relative;
    justify-content: space-between;
    margin: 0 -16px;
    @media all and (max-width: 1200px) {
      margin: 0 -8px;
    }

    .nav_burger {
      display: none;
      margin-left: auto;
      margin-right: 12px;
      @media all and (max-width: 1024px) {
        display: block;
      }
    }

    .mobile_hidden {
      @media all and (max-width: 576px) {
        display: none !important;
      }
    }

    .main_button {
      @media all and (max-width: 576px) {
        padding: 6px 16px;
      }
    }
  }

  .nav_logo {
    margin-right: 16px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 80px;
      @media all and (max-width: 576px) {
        width: 50px;
      }
    }
  }

  .nav_list_main, .nav_list_add {
    display: flex;
    //margin: 0 -16px;
    @media all and (max-width: 1200px) {
      //margin: 0 -8px;
    }

    &_items {
      display: flex;
      align-items: center;
      padding: 0 16px 0;
      margin: 0 -16px;
      @media all and (max-width: 1200px) {
        padding: 0 8px 0;
        margin: 0 -8px;
      }
    }

    .main_nav_item {
      padding: 0 16px 0;
      box-sizing: border-box;
      text-align: center;
      @media all and (max-width: 1200px) {
        padding: 0 8px 0;
      }

      a {
        color: $white_text_color;
        text-decoration: none;
        font-family: $Inter-400;
        line-height: 16px;
        font-size: 12px;
        letter-spacing: 3.2px;
        text-transform: uppercase;
        text-align: center;

        @media all and (max-width: 1300px) {
          letter-spacing: 2px;
        }
      }
    }

    .nav_item {
      padding: 0 16px 0;
      box-sizing: border-box;
      display: flex;
      @media all and (max-width: 1200px) {
        padding: 0 8px 0;
      }

      a {
        color: $white_text_color;
        text-decoration: none;
        font-family: $Inter-400;
        line-height: 16px;
        font-size: 12px;
      }
    }
  }

  .nav_list_main {
    @media all and (max-width: 1024px) {
      transform: scaleY(0);
      opacity: 0;
      transform-origin:  top;
      transition: 0.3s scale ease-in-out, 0.5s opacity ease-in-out;
      position: fixed;
      top: 90px;
      background: #1d1f20;
      display: flex;
      width: 100%;
      left: 0;
      right: 0;
      margin: 0;
      padding: 16px 8px;
      box-sizing: border-box;
      .nav_list_main_items {
        box-sizing: border-box;
        margin: 0;
        width: 100%;
        justify-content: center;
      }
    }
    @media all and (max-width: 767px) {
      .nav_list_main_items {
        flex-direction: column;

        .main_nav_item {
          margin-bottom: 12px;

          a {
            font-size: 14px;
          }
        }
      }
    }
    @media all and (max-width: 576px) {
      top: 85px
    }
  }

  .lang {
    .lang_list {
      position: relative;

      .lang_hidden_wrapper {
        display: flex;
        top: 100%;
        padding: 16px;
        position: absolute;
        transform: scaleY(0);
        transform-origin: top;
        box-sizing: border-box;
        right: 0;
        min-width: 200px;
        border-radius: 10px;
        background: rgba(255, 255, 255, 0.04);
        box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.08);
        backdrop-filter: blur(10px);
        z-index: 2;
        opacity: 0;
        transition: 0.3s scale ease-in-out, 0.5s opacity ease-in-out;

        ol {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
        }

        li {
          margin-bottom: 6px;
          width: 100%;

          a {
            width: 100%;
            white-space: nowrap;
            color: $black_text_color;
            font-size: 16px;
            text-decoration: none;
            font-family: $Inter-400;
            letter-spacing: 0.6px;
            font-weight: 400;
            display: flex;
            align-items: center;
            position: relative;

            .href_lang {
              display: flex;
              width: 100%;
              justify-content: space-between;
              align-items: center;
              color: $white_text_color;
              font-size: 14px;
              line-height: 18px;
              font-family: $Inter-400;
              &_img {
                display: block;
                width: 18px;
                height: 14px;
                overflow: hidden;
                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  object-position: center;
                }
              }
            }
          }
        }

      }


      &:hover {

        .lang_hidden_wrapper {
          transform: scaleY(1);
          transition: 0.3s scale ease-in-out, 0.5s opacity ease-in-out;
          opacity: 1;
        }

        .lang_current:after {
          transition: opacity 0.2s ease-in-out;
          opacity: 1;

        }
      }

      .lang_current {
        &:hover {
          .lang_current_img {
            .dropdown_svg {
              transform: rotateX(180deg);
            }
          }
        }

        &_img {
          display: flex;
          line-height: 16px;
          color: $white_text_color;
          text-decoration: none;
          font-family: $Inter-400;
          font-size: 12px;
          align-items: center;

          svg {
            margin-right: 4px;
            fill: #fff;
          }

          .dropdown_svg {
            margin-left: 5px;
          }
        }
      }
    }
  }

  .lang_list {

  }


}