.audit {
  scroll-margin-top: 150px;
  padding: 0 0 80px;
  box-sizing: border-box;
  @media all and (max-width: 1024px) {
    padding: 0 0 60px;
  }
  @media all and (max-width: 767px) {
    padding: 0 0 40px;
  }

  &_items {
    display: flex;
    justify-content: space-between;
    margin: 0 -16px 0;
    width: 100%;
    align-items: center;
    @media all and (max-width: 576px) {
      flex-wrap: wrap;
      justify-content: center;
    }

    .audit_item {
      padding: 0 16px 0;
      box-sizing: border-box;
      display: flex;
      align-items: center;

      &:nth-child(1) {
        justify-content: flex-start;
        flex: 2;
        @media all and (max-width: 576px) {
          flex: inherit;
          width: 100%;
          justify-content: center;
          margin-bottom: 10px;
        }

        img {
          max-width: 44px;
          @media all and (max-width: 576px) {
            max-width: 24px;
          }
        }
      }

      &:nth-child(2) {
        justify-content: center;
        flex: 1;
        @media all and (max-width: 576px) {
          flex: inherit;
          width: 50%;
        }
      }

      &:nth-child(3) {
        justify-content: flex-end;
        flex: 1;
        @media all and (max-width: 576px) {
          flex: inherit;
          width: 50%;
        }
      }

      img {
        width: 100%;
      }

      &_text {
        margin-left: 6px;
        color: $white_text_color;
        font-family: $Inter-600;
        font-size: 25px;
        font-style: normal;
        line-height: 48px;
        white-space: nowrap;
        @media all and (max-width: 1024px) {
          font-size: 16px;
        }
      }
    }
  }
}