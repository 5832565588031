@font-face {
    font-family: 'Inter-300';
    src: url("/fonts/Inter/static/Inter-Thin.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
    font-stretch: normal;
}
@font-face {
    font-family: 'Inter-400';
    src: url("/fonts/Inter/static/Inter-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    font-stretch: normal;
}
@font-face {
    font-family: 'Inter-500';
    src: url("/fonts/Inter/static/Inter-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
    font-stretch: normal;
}
@font-face {
    font-family: 'Inter-600';
    src: url("/fonts/Inter/static/Inter-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
    font-stretch: normal;
}
@font-face {
    font-family: 'Inter-700';
    src: url("/fonts/Inter/static/Inter-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
    font-stretch: normal;
}
@font-face {
    font-family: 'Inter-900';
    src: url("/fonts/Inter/static/Inter-Black.ttf") format("truetype");
    font-weight: 900;
    font-style: normal;
    font-display: swap;
    font-stretch: normal;
}