.roadmap {
  scroll-margin-top: 150px;
  padding: 20px 0 60px;
  box-sizing: border-box;

  &_title {
    color: $white_text_color;
    text-align: center;
    font-family: $Inter-700;
    font-size: 60px;
    font-style: normal;
    margin: 0 0 60px;
    line-height: 75px; /* 125% */
    @media all and (max-width: 767px){
      font-size: 32px;
      line-height: 38px;
    }
    @media all and (max-width: 767px){
      font-size: 24px;
      line-height: 26px;
      margin: 0 0 30px;
    }
  }

  &_slider {
    padding: 0 80px;
    box-sizing: border-box;
    @media all and (max-width: 768px) {
      padding: 0 50px;
    }
    @media all and (max-width: 576px) {
      padding: 0 46px;
    }

    .swiper {
      overflow: visible;
      max-width: 870px;
    }

    &_slide {
      position: relative;

      .blur {
        position: absolute;
        content: '';
        width: 180px;
        height: 180px;
        left: 0;
        top: 0;
        background: #4D9636;
        opacity: 0.5;
        filter: blur(100px);
      }

      h3 {
        color: $white_text_color;
        font-family: $Inter-700;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin: 0 0 10px;
      }

      h4 {
        color: $white_text_color_70;
        font-family: $Inter-400;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 21px */
        margin: 0 0 20px;
      }

      ul {
        list-style: disc;
        padding: 0 0 0 10px;
        box-sizing: border-box;

        li {
          color: $white_text_color_70;
          font-family: $Inter-400;
          font-size: 12px;
          margin: 0 0 6px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 21px */
          .content {
            display: flex;
            justify-content: space-between;

            svg {
              margin-left: auto;
              @media all and (max-width: 576px) {
                width: 12px;
              }
            }
          }


        }
      }
    }

    .swiper-wrapper {
      //overflow: hidden;

      .swiper-slide {
        overflow: hidden;
        height: auto;
        border-radius: 20px;
        border: 1px solid rgba(107, 243, 64, 0.29);
        background: linear-gradient(110deg, rgba(77, 150, 54, 0.08) -16%, rgba(77, 150, 54, 0.01) 105.19%);
        backdrop-filter: blur(30.171428680419922px);
        padding: 20px;
        box-sizing: border-box;
        @media all and (max-width: 576px) {
          padding: 20px 14px;
        }
      }
    }

    .swiper-button-prev, .swiper-button-next {
      top: 50%;
      z-index: 2;
      width: 56px;
      height: 56px;
      border-radius: 50%;
      background: #4D9636;
      backdrop-filter: blur(25px);
      @media all and (max-width: 768px) {
        width: 30px;
        height: 30px;
      }

      &:after {
        font-size: 20px;
        color: #000;
      }
    }

    .swiper-button-next {
      right: -68px;
      @media all and (max-width: 768px) {
        right: -38px;

      }

      &.swiper-button-disabled {
        opacity: 0 !important;
      }
    }

    .swiper-button-prev {
      left: -68px;
      @media all and (max-width: 768px) {
        left: -38px;
      }

      &.swiper-button-disabled {
        opacity: 0 !important;
      }
    }

  }
}