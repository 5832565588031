.features {
  scroll-margin-top: 150px;
  padding: 80px 0 50px;
  box-sizing: border-box;
  @media all and (max-width: 1024px) {
    padding: 30px 0 50px;
  }

  &_items {
    display: flex;
    @media all and (max-width: 1024px) {
      flex-direction: column;
    }

    .feature_item {
      width: 33.3%;
      padding: 0 40px 0;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;
      @media all and (max-width: 1024px) {
        width: 100%;
        align-items: flex-start;
      }

      &_title {
        display: flex;
        flex-direction: column;
        align-items: center;
        @media all and (max-width: 1024px) {
          flex-direction: row;
          align-items: center;
          margin-bottom: 20px;
          img {
            @media all and (max-width: 1024px) {
              width: 50px;
              height: auto;
            }
            @media all and (max-width: 576px) {
              width: 30px;
            }
          }
        }
      }

      &:nth-child(1) {
        padding-left: 0;
        @media all and (max-width: 1024px) {
          padding: 0 0 20px;
          border-bottom: 1px solid #7F7F7F;
          margin-bottom: 20px;
        }
      }

      &:nth-child(2) {
        border-right: 1px solid #7F7F7F;
        border-left: 1px solid #7F7F7F;

        img {
          @media all and (max-width: 1024px) {
            width: 30px;
          }
          @media all and (max-width: 576px) {
            width: 20px;
          }
        }

        @media all and (max-width: 1024px) {
          padding: 0 0 20px;
          border-right: none;
          border-left: none;
          border-bottom: 1px solid #7F7F7F;
          margin-bottom: 20px;
        }
      }

      &:nth-child(3) {
        padding-right: 0;

        img {
          @media all and (max-width: 1024px) {
            width: 40px;
          }
          @media all and (max-width: 576px) {
            width: 30px;
          }
        }

        @media all and (max-width: 1024px) {
          padding: 0;
        }
      }

      img {
        margin: 0 0 40px;

        @media all and (max-width: 1024px) {
          margin: 0 16px 0 0;
        }
        @media all and (max-width: 576px) {
          margin: 0 10px 0 0;
        }

      }

      h3 {
        color: #FFF;
        text-align: center;
        font-family: $Inter-600;
        font-size: 32px;
        font-style: normal;
        line-height: 48px;
        padding: 0 0 40px;
        box-sizing: border-box;
        max-width: 250px;
        margin: 0;
        @media all and (max-width: 1024px) {
          max-width: inherit;
          padding: 0;
        }
        @media all and (max-width: 767px) {
          font-size: 24px;
          line-height: 28px;
        }
        @media all and (max-width: 576px) {
          font-size: 18px;
          line-height: 26px;
        }
      }

      &_text {
        padding-top: 16px;
        box-sizing: border-box;
        border-top: 1px solid #7F7F7F;
        @media all and (max-width: 1024px) {
          border: none;
          padding: 0;
        }
      }

      p {
        margin: 0 0 6px;
        max-width: 283px;
        color: $white_text_color;
        font-family: $Inter-400;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;
        @media all and (max-width: 1024px) {
          max-width: inherit;
        }
        @media all and (max-width: 767px) {
          line-height: 26px;
        }
        @media all and (max-width: 576px) {
          line-height: 24px;
        }
      }
    }
  }

}