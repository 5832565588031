.prize {
  padding: 50px 0 50px;
  box-sizing: border-box;

  @media all and (max-width: 1024px){
    padding: 30px 0 50px;
  }

  &_title {
    text-transform: uppercase;
    font-family: $Inter-700;
    font-size: 48px;
    font-style: normal;
    line-height: 60px;
    color: $green_text_color;
    margin: 0;
    @media all and (max-width: 767px){
      font-size: 32px;
      line-height: 38px;
      margin: 0 0 10px;
    }

    @media all and (max-width: 576px){
      font-size: 20px;
      line-height: 26px;
    }
  }

  &_sub_title {
    color: $white_text_color;
    font-family: $Inter-400;
    font-size: 16px;
    font-style: normal;
    line-height: 24px;
    margin: 0;
    max-width: 480px;
  }

  &_image {
    margin-top: -40px;
    width: 100%;
    height: auto;
    @media all and (max-width: 1024px){
      margin-top: -10px;
    }
    @media all and (max-width: 576px){
     margin-top: 0;
    }
  }
}