@import "variables";

.footer {
  &_main {
    padding: 80px 0 20px;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 163px;
    max-width: 1100px;
    margin: 0 auto;

    @media all and (max-width: 1024px) {
      gap: 16px;
    }
    @media all and (max-width: 767px) {
      display: flex;
      flex-direction: column;
      padding: 20px 0 20px;
    }

    .footer_block_title {
      color: $white_text_color;
      font-family: $Inter-400;
      font-size: 24px;
      font-style: normal;
      line-height: 32px; /* 133.333% */
      padding: 0 0 24px;
      margin: 0 0 24px;
      box-sizing: border-box;
      border-bottom: 1px solid #7F7F7F;
      @media all and (max-width: 767px) {
        font-size: 20px;
        padding: 0 0 12px;
        margin: 0 0 12px;
        text-align: center;
      }
    }

    .footer_main_item {
      margin-bottom: 24px;

      li {
        margin: 0 0 16px;
        color: $white_text_color;
        font-family: $Inter-400;
        font-size: 16px;

        @media all and (max-width: 767px) {
          font-size: 14px;
          text-align: center;
          margin: 0 0 8px;
        }

        &:last-child {
          margin: 0;
        }

        a {
          color: $white_text_color;
          font-family: $Inter-400;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          text-decoration: none;
          @media all and (max-width: 767px) {
            font-size: 14px;
            margin: 0 0 8px;
          }
        }
      }
    }

    .main_nav {
      display: flex;
      flex-direction: column;
      @media all and (max-width: 767px) {
        order: 2;
      }
    }

    .footer_logo {
      display: flex;
      justify-content: center;
      @media all and (max-width: 767px) {
        order: 1;
      }

      img {
        width: 100%;
        height: auto;
        @media all and (max-width: 767px) {
          max-width: 150px;
        }
      }
    }

    .footer_info {
      display: flex;
      flex-direction: column;

      @media all and (max-width: 767px) {
        order: 3;
      }

      a {
        text-decoration: underline !important;
      }

      .footer_social {
        display: flex;
        @media all and (max-width: 767px) {
         justify-content: center;
        }

        .social_item {
          width: 40px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 12px;
          background: #2D2D2D;
          box-shadow: 0px 20px 25px -5px rgba(0, 0, 0, 0.10), 0px 8px 10px -6px rgba(0, 0, 0, 0.10);
          @media all and (max-width: 767px) {
            width: 30px;
            height: 30px;
          }

          &:first-child {
            margin-right: 30px;
          }
        }
      }
    }
  }

  &_bottom {
    border-top: 1px solid #7F7F7F;
    display: flex;
    padding: 60px 0 80px;
    justify-content: center;
    @media all and (max-width: 767px) {
      border-top: none;
    }
    @media all and (max-width: 767px) {
      flex-wrap: wrap;
      justify-content: center;
      padding: 30px 0 60px;
    }

    .list_item {
      padding: 0 12px;
      a {
        text-decoration: none;
        color: $white_text_color;
        font-family: $Inter-400;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */

        @media all and (max-width: 767px) {
          font-size: 14px;
        }
      }
    }
  }

  &_disclaimer {
    margin: 0 -20px;
    display: flex;
    flex-wrap: wrap;
    @media all and (max-width: 767px) {
      margin: 0 -16px;
    }

    &_item {
      width: 50%;
      padding: 0 20px 20px;
      box-sizing: border-box;
      margin-bottom: 20px;
      position: relative;
      @media all and (max-width: 1024px) {
        width: 100%;
      }
      @media all and (max-width: 767px) {
        padding: 0 16px 20px;
      }

      &:after {
        position: absolute;
        bottom: 0;
        height: 1px;
        width: calc(100% - 40px);
        content: '';
        background: #7F7F7F;
        left: 20px;
      }

      .item_wrapper {
        padding-left: 40px;
        position: relative;
        color: $white_text_color;
        font-family: $Inter-400;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 171.429% */

        &_key {
          position: absolute;
          left: 0;
          border-radius: 50%;
          width: 20px;
          height: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #4D9636;
          color: #2D2D2D;
          font-family: $Inter-400;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 142.857% */
        }
      }
    }
  }
}