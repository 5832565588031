.featured {
  padding: 50px 0 80px;
  @media all and (max-width: 1024px) {
    padding: 50px 0 40px;
  }

  &_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &_title {
    font-family: $Inter-400;
    text-transform: uppercase;
    font-size: 32px;
    font-style: normal;
    line-height: 28px;
    color: $white_text_color;
    letter-spacing: 12px;
    margin: 0 0 50px;
    text-align: center;
    @media all and (max-width: 1024px) {
      letter-spacing: 6px;
      margin: 0 0 30px;
    }
    @media all and (max-width: 768px) {
      margin: 0 0 20px;
    }
    @media all and (max-width: 576px) {
      margin: 0 0 16px;
      font-size: 20px;
      letter-spacing: 3px;
    }
  }

  &_items {
    display: flex;

    box-sizing: border-box;
    align-items: center;
    justify-content: space-between;
    @media all and (max-width: 767px) {
      padding: 0;
    }
    @media all and (max-width: 576px) {
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
    }
  }

  &_item {
    padding: 0 32px;
    box-sizing: border-box;
    @media all and (max-width: 1024px) {
      padding: 0 16px;
    }
    @media all and (max-width: 576px) {
      margin-bottom: 12px;
    }

    &:first-child {
      padding-left: 0;
      @media all and (max-width: 767px) {
        padding-left: inherit;
      }
    }

    &:last-child {
      padding-right: 0;
      margin: 0;
      @media all and (max-width: 767px) {
        padding-right: inherit;
      }
    }

    img {
      display: block;
      width: 100%;
      height: auto;
      object-fit: contain;
      @media all and (max-width: 767px) {
        max-width: 140px;
      }
      @media all and (max-width: 576px) {
        max-width: 125px;
      }
    }
  }
}