//Text Colors
$black_text_color: rgba(0, 0, 0);
$black_text_color_50: rgba(0, 0, 0, 0.5);
$white_text_color: rgba(255, 255, 255);
$white_text_color_50: rgba(255, 255, 255, 0.5);
$white_text_color_70: rgba(255, 255, 255, 0.7);
$green_text_color: #4D9636;

//Fonts
$Inter-300: Inter-300, san-serif;
$Inter-400: Inter-400, san-serif;
$Inter-500: Inter-500, san-serif;
$Inter-600: Inter-600, san-serif;
$Inter-700: Inter-700, san-serif;
$Inter-900: Inter-900, san-serif;

//Colors
$bg_color: #1d1f20;
