.team {
  padding: 80px 0 60px;
  box-sizing: border-box;
  @media all and (max-width: 1024px){
    padding: 40px 0 60px;
  }

  &_title {
    color: $white_text_color;
    font-family: $Inter-700;
    font-size: 48px;
    font-style: normal;
    line-height: 60px; /* 125% */
    margin: 0 0 40px;
    @media all and (max-width: 767px){
      font-size: 24px;
      line-height: 26px;
      margin: 0 0 30px;
    }
    @media all and (max-width: 576px){
      text-align: center;
    }

  }

  &_wrapper {
    max-width: 1092px;
    margin: 0 auto;

    .team_items {
      display: flex;
      margin: 0 -20px 0;
      justify-content: center;
      @media all and (max-width: 1024px){
        margin: 0 -10px 0;
      }
      @media all and (max-width: 767px){
        flex-wrap: wrap;
      }

      .team_item {
        flex: 1;
        padding: 0 20px 0;
        box-sizing: border-box;
        @media all and (max-width: 1024px){
          padding: 0 10px 0;
        }
        @media all and (max-width: 767px){
          flex: inherit;
          width: 50%;
          margin-bottom: 20px;
        }
        @media all and (max-width: 576px){
          width: 100%;
          padding: 0 56px 0;
        }

        &_img {
          width: 100%;
          margin: 0 0 30px;
          height: auto;
          @media all and (max-width: 767px){
            margin: 0 0 10px;
          }
        }

        &_title {
          color: $white_text_color;
          font-family: $Inter-500;
          font-size: 24px;
          font-style: normal;
          line-height: 32px;
          margin: 0;
          @media all and (max-width: 1024px){
            font-size: 18px;
            line-height: 24px;
          }
        }

        &_position {
          color: #888;
          font-family: $Inter-400;
          font-size: 16px;
          font-style: normal;
          line-height: 24px;
          margin: 0;
          @media all and (max-width: 1024px){
            font-size: 14px;
          }
        }
      }
    }
  }
}